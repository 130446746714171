import dadosPessoais from './dados-pessoais';
import enderecos from './enderecos';
import certificados from './certificados';

const aluno = {
	namespaced: true,
	modules: {
		dadosPessoais,
		enderecos,
		certificados
	}
};

export default aluno;
