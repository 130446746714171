const mutations = {
	add(state, payload) {
		state.list.push(payload);
	},

	remoteFirst(state) {
		state.list.shift();
	},
};

export default mutations;
