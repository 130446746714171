import http from '@commons/http';
import { setRefreshToken } from '@commons/authenticate'

const actions = {
	async login({ commit, dispatch }, loginData) {
		const data = (await http.post('/v1/auth/Login', loginData)).data;

		commit('setToken', {
			token: data.token,
			refreshToken: data.refreshToken,
		});

		setRefreshToken(data.refreshToken);

		dispatch('setData', data);

		commit('setVerified', true);
	},

	async getRecoverMethods() {
		
	}

};

export default actions;
