
const createDate = (year, month, day = 1, hours = 0, minutes = 0, seconds = 0, milliseconds = 0) => {
	return new Date(year, month - 1, day, hours, minutes, seconds, milliseconds);
};

const parseDate = date => {
	const fragment = date.match(/^(?:(\d{1,2})\/(\d{1,2})\/(\d{4})|(\d{4})-(\d{1,2})-(\d{1,2}))(?: (\d{1,2}):(\d{1,2})(?::(\d{1,2})(?:\.(\d+))?)?)?$/)

	if (!fragment) {
		return false;
	}

	return createDate(
		parseInt(fragment[3] || fragment[4], 10),
		parseInt(fragment[2] || fragment[5], 10),
		parseInt(fragment[1] || fragment[6], 10),
		parseInt(fragment[7] || 0, 10),
		parseInt(fragment[8] || 0, 10),
		parseInt(fragment[9] || 0, 10),
		parseInt(fragment[10] || 0, 10),
	);
};

const compareDateAsc = (a, b) => {
	return parseDate(a).getTime() - parseDate(b).getTime();
}

const compareDateDesc = (a, b) => {
	return parseDate(b).getTime() - parseDate(a).getTime();
}

const getLocaleDate = date => {
	return parseDate(date).toLocaleDateString();
}

const getLocaleDateWithTime = date => {
	return parseDate(date).toLocaleString();
}

export {
	createDate,
	parseDate,
	compareDateAsc,
	compareDateDesc,
	getLocaleDate,
	getLocaleDateWithTime,
};
