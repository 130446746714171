import http from './http';
import vuex from '../store/store';
import router from '../router/router';

const login = async (data) => {
	return await http.post('/v1/auth/Login', data);
}

const setRefreshToken = async (refreshToken) => {
	return await http.post(location.protocol+'//'+location.hostname+'/refresh_token.php', 'refreshToken='+refreshToken);
}

const getRefreshToken = async () => {
	return await http.get(location.protocol+'//'+location.hostname+'/refresh_token.php');
}

const getNewToken = async (refreshToken) => {
	/*let requestBody = {
        refreshToken: refreshToken
    };
    requestBody = new URLSearchParams(Object.entries(requestBody)).toString();*/
	return await http.post('/v1/auth/refresh', 'refreshToken='+refreshToken);
}

const checkToken = async (new_token) => {
	if (
		(typeof vuex.state.token == 'string' || vuex.state.token instanceof String)
        && new_token.length > 20
        && new_token.split(".").length >= 2
	)
		return true;
	return false;
}

const generateToken = async () => {
	var data_comparacao = new Date();
	data_comparacao.setSeconds(-30);
	if (
		typeof vuex.state.token_request_time != 'undefined'
            && vuex.state.token_request_time >= data_comparacao
            && checkToken(vuex.state.token)
	) {
		return vuex.state.token;
	} else {
		let refreshToken = await getRefreshToken()
			.then((response) => {
				return response.data.refreshToken;
			}).catch(() => {
				vuex.dispatch('sessao/setToken', null);
				router.push({name: 'login.home'});
			});

		let newToken = '';
		if (checkToken(refreshToken)) {
			newToken = await getNewToken(refreshToken)
				.then((response) => {
					setRefreshToken(response.data.refreshToken);
					return response.data.token;
				}).catch(() => {
					vuex.dispatch('sessao/setToken', null);
					router.push({name: 'login.home'});
				});
		}
		vuex.commit('setToken', newToken);
		return newToken;
	}
}


const setRefreshTokenOffice = async (refreshToken) => {
	return await http.post(location.protocol+'//'+location.hostname+'/refresh_token.php', 'refreshToken='+refreshToken+'&authOffice=true');
}

const getRefreshTokenOffice = async () => {
	return await http.get(location.protocol+'//'+location.hostname+'/refresh_token.php');
}

export {
	login,
	setRefreshToken,
	getRefreshToken,
	getNewToken,
	checkToken,
	generateToken,
	setRefreshTokenOffice,
	getRefreshTokenOffice
}
