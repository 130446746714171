import { pdfDownloader } from '@/commons/helpers';
import moment from 'moment/moment';
import http from '@commons/http';
import { isNil, isObjectLike } from 'lodash';

const actions = {
	todosPagamentos: async ({ commit, rootGetters }) => {
		const vinculo = rootGetters['vinculosAcademicos/getVinculoSelecionado'];

		let data = null;

		if (!isNil(vinculo) && isObjectLike(vinculo)) {
			data = {
				nens_id: vinculo.nens_id,
				curs_id: vinculo.curs_id,
				habi_id: vinculo.habi_id,
				turn_id: vinculo.turn_id,
				viac_sequencia: vinculo.viac_sequencia,
				peri_id: vinculo.peri_id
			};
		}

		try {
			commit('loading', true);

			const response = await http.get(
				'/v1/aluno/pagamentos/lista-pagamentos',
				data
			);

			if (response.status === 200) {
				let abertos = response.data.message.abertos || [];
				let historico = response.data.message.historico || [];

				let atrasados = abertos.filter(
					boleto =>
						moment(boleto.data_vencimento).format('YYYYMMDD') <
						moment().format('YYYYMMDD')
				);

				abertos = abertos.filter(
					boleto =>
						moment(boleto.data_vencimento).format('YYYYMMDD') >=
						moment().format('YYYYMMDD')
				);

				if (
					!isObjectLike(vinculo) &&
					vinculo.toLowerCase() === 'todos os cursos ativos'
				) {
					const cursos = rootGetters['cursos/getCursosAtivos'];

					abertos = abertos.filter(aberto => cursos.includes(aberto.curs_id));
					atrasados = atrasados.filter(atrasado =>
						cursos.includes(atrasado.curs_id)
					);
					historico = historico.filter(pago => cursos.includes(pago.curs_id));
				}

				commit('setAbertos', abertos);
				commit('setAtrasados', atrasados);
				commit('setPagos', historico);
			}
		} catch (error) {
			console.info(error?.data?.message);
		} finally {
			commit('loading', false);
		}
	},

	gerarPix: async (_, id) => {
		try {
			const response = await http.get('/v1/aluno/pagamentos/pix', { doc_id: id });
			if (response.status === 200) {
				return response.data;
			}
			return null;
		} catch (error) {
			console.error("Erro ao buscar dados do PIX:", error);
			return null;
		}
	},

	gerarBoleto: async (_, id) => {
		try {
			const response = await http.get(
				'/v1/aluno/pagamentos/boleto-pdf',
				{ doc_id: id },
				{},
				'blob'
			);

			if (response.status === 200) {
				pdfDownloader(response.data, 'boleto-aberto.pdf');
				return true;
			}

			return false;
		} catch (error) {
			return false;
		}
	},

	// eslint-disable-next-line
	regerarBoleto: async ({ commit, rootGetters }, id) => {
		commit('loading', true);
		try {
			const data = {
				doc_id: id
			};

			const response = await http.get(
				'/v1/aluno/pagamentos/regerar-boleto',
				data,
				{}
			);

			if (response.status === 200) {
				const vinculo = rootGetters['vinculosAcademicos/getVinculoSelecionado'];

				let dataAntiga = null;

				if (!isNil(vinculo) && isObjectLike(vinculo)) {
					dataAntiga = {
						nens_id: vinculo.nens_id,
						curs_id: vinculo.curs_id,
						habi_id: vinculo.habi_id,
						turn_id: vinculo.turn_id,
						viac_sequencia: vinculo.viac_sequencia,
						peri_id: vinculo.peri_id
					};
				}

				const listaantiga = await http.get(
					'/v1/aluno/pagamentos/lista-pagamentos',
					dataAntiga
				);

				if (listaantiga.status === 200) {
					let abertos = listaantiga.data.message.abertos || [];
					let historico = listaantiga.data.message.historico || [];

					let atrasados = abertos.filter(
						boleto =>
							moment(boleto.data_vencimento).format('YYYYMMDD') <
							moment().format('YYYYMMDD')
					);

					abertos = abertos.filter(
						boleto =>
							moment(boleto.data_vencimento).format('YYYYMMDD') >=
							moment().format('YYYYMMDD')
					);

					commit('setAbertos', abertos);
					commit('setAtrasados', atrasados);
					commit('setPagos', historico);
				}
				return response.data.message.abertos[0].doc_id;
			}
		} catch (error) {
			return false;
		} finally {
			commit('loading', false);
		}
	},
	// eslint-disable-next-line
	copiarClipboard: async ({ commit, rootGetters }, id) => {
		commit('loading', true);
		try {
			const data = {
				doc_id: id
			};

			const response = await http.get(
				'/v1/aluno/pagamentos/regerar-boleto',
				data,
				{}
			);

			if (response.status === 200) {
				const codigo_barras =
					response.data.message.abertos[0].doc_codigo_barras;

				const vinculo = rootGetters['vinculosAcademicos/getVinculoSelecionado'];

				let dataAntiga = null;

				if (!isNil(vinculo) && isObjectLike(vinculo)) {
					dataAntiga = {
						nens_id: vinculo.nens_id,
						curs_id: vinculo.curs_id,
						habi_id: vinculo.habi_id,
						turn_id: vinculo.turn_id,
						viac_sequencia: vinculo.viac_sequencia,
						peri_id: vinculo.peri_id
					};
				}

				const listaantiga = await http.get(
					'/v1/aluno/pagamentos/lista-pagamentos',
					dataAntiga
				);

				if (listaantiga.status === 200) {
					let abertos = listaantiga.data.message.abertos || [];
					let historico = listaantiga.data.message.historico || [];

					let atrasados = abertos.filter(
						boleto =>
							moment(boleto.data_vencimento).format('YYYYMMDD') <
							moment().format('YYYYMMDD')
					);

					abertos = abertos.filter(
						boleto =>
							moment(boleto.data_vencimento).format('YYYYMMDD') >=
							moment().format('YYYYMMDD')
					);

					commit('setAbertos', abertos);
					commit('setAtrasados', atrasados);
					commit('setPagos', historico);

					return codigo_barras;
				}
			}
		} catch (error) {
			return false;
		} finally {
			commit('loading', false);
		}
	},

	gerarComprovanteCartao: async ({ commit }, id) => {
		commit('loading', true);

		try {
			const response = await http.get(
				'/v1/aluno/pagamentos/comprovante-online-pdf',
				{ pgto_id: id },
				{},
				'blob'
			);

			if (response.status === 200) {
				pdfDownloader(response.data, 'comprovante-pagamento-cartao.pdf');
				return true;
			}

			return false;
		} catch (error) {
			return false;
		} finally {
			commit('loading', false);
		}
	},

	gerarComprovanteBoleto: async ({ commit }, id) => {
		commit('loading', true);

		try {
			const response = await http.get(
				'/v1/aluno/pagamentos/boleto-pdf',
				{ doc_id: id },
				{},
				'blob'
			);

			if (response.status === 200) {
				pdfDownloader(response.data, 'comprovante-pagamento-boleto.pdf');
				return true;
			}

			return false;
		} catch (error) {
			return false;
		} finally {
			commit('loading', false);
		}
	},

	ativarRecorrencia: async () => {
		try {
			const data = {
				acao_cartao_padrao: 'ativar_recorrencia',
			};

			const response = await http.post('/v1/aluno/pagamentos/recorrencia-cartao',
				new URLSearchParams(Object.entries(data)).toString(),
			);

			if (
				response.status !== 200
				|| response.data.status != 'success'
			) {
				throw new Error('error');
			}

			return true;
		} catch (error) {
			throw new Error('Não foi possível ativar a recorrência agora, tente novamente mais tarde');
		}
	},

	desativarRecorrencia: async () => {
		try {
			const data = {
				acao_cartao_padrao: 'desativar_recorrencia',
			};

			const response = await http.post('/v1/aluno/pagamentos/recorrencia-cartao',
				new URLSearchParams(Object.entries(data)).toString(),
			);

			if (
				response.status !== 200
				|| response.data.status != 'success'
			) {
				throw new Error('error');
			}

			return true;
		} catch (error) {
			throw new Error('Não foi possível desativar a recorrência agora, tente novamente mais tarde');
		}
	},
};

export default actions;
