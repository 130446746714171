const payloadHandler = payload => typeof payload == 'object'
	? payload
	: { message: payload };

const actions = {
	openSuccess({ commit }, payload) {
		commit('add', { ...payloadHandler(payload), type: 'is-success' });
	},

	openError({ commit }, payload) {
		commit('add', { ...payloadHandler(payload), type: 'is-danger' });
	},

	openInfo({ commit }, payload) {
		commit('add', { ...payloadHandler(payload), type: 'is-info' });
	},

	remoteFirst({ commit }) {
		commit('remoteFirst');
	},
};

export default actions;
