import http from '@commons/http';


const actions = {
	async getBloqueioDisciplinas({ commit }) {
		// Remova a chave extra entre commit e o parêntese
		try {
			const response = await http.get('/get-bloqueio-disciplinas');

			if (response.status === 200) {
				const { bloqueio, mensagem } = response.data.message;

				commit('setBloqueioDisciplinas', { bloqueio, mensagem });
			} else {
				throw new Error('Erro ao obter os dados de bloqueio de disciplinas');
			}
		} catch (error) {
			console.error('Erro ao obter os dados de bloqueio de disciplinas', error);
		}
	}
};

export default actions;
