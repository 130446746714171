export default function (store) {
	const setIsMobile = () => {
		const isMobile = window.innerWidth <= 768;

		if (store.getters['ui/isMobile'] !== isMobile) {
			store.dispatch('ui/setMobile', isMobile);
		}
	};

	setIsMobile();

	window.addEventListener('resize', setIsMobile);
}
