const state = {
	loading: false,
	progress: 33,
	isModalActive: false,
	activeStep: 'aviso',
	secoes: null,
	secaoSelecionada: null,
	categoriaSelecionada: null,
	tipoSelecionado: null,
	labelExtra: null,
	informacaoExtra: null,
	files: [],
	motivoSolicitacao: null,
	ultimaCarga: 0,
	ultimoFiltro: '',
	listagem: [],
	detalhes: {},
	retornoPagamento: null,
	feedback: {
		openModal: false,
		openDetalheModal: false,
	},
	disciplinasNaoDisponiveis: [],
	disciplinasNaoDisponiveisSelecionadas: [],
};

export default state;
