const checkIsDev = hostname => hostname === 'localhost' || hostname.includes('.localhost');
const checkIsQea = hostname => hostname.includes('qea.');
const checkIsHmg = hostname => hostname.includes('hmg.');
const checkIsTst = hostname => checkIsDev(hostname) || checkIsQea(hostname) || checkIsHmg(hostname);
const checkIsPrd = hostname => hostname.includes('ead.br') && !checkIsTst(hostname);

const hostname = window.location.hostname.toLocaleLowerCase();
const isDev = checkIsDev(hostname);
const isQea = checkIsQea(hostname);
const isHmg = checkIsHmg(hostname);
const isTst = checkIsTst(hostname);
const isPrd = checkIsPrd(hostname);

export {
	isDev,
	isQea,
	isHmg,
	isTst,
	isPrd,
}
