const getters = {
	hasToast(state) {
		return Boolean(state.list.length);
	},

	getFirst(state) {
		return state.list[0];
	},
};

export default getters;
