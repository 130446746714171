/** @todo renomear arquivo */

/**
 * @param {function(...*):Promise} func - Async callable
 * @returns {Promise}
 */
export function noConcurrency(func) {
	let instance;

	return (...args) => instance
		|| (
			instance = (async () => {
				try {
					return await func(...args);
				} finally {
					instance = undefined;
				}
			})()
		);
}
