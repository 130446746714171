export default {
	loading(state, isLoading) {
		const count = state.loading + (isLoading ? 1 : -1);
		state.loading = count > 0 ? count : 0;
	},

	setMobile(state, isMobile) {
		state.mobile = isMobile;
	},
};
