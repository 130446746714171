import actions from './actions';
import state from './state';
import getters from './getters';
import mutations from './mutations';
import modules from './modules';

const ui = {
	namespaced: true,
	actions,
	state,
	getters,
	mutations,
	modules,
};

export default ui;
