const getters = {
	getEmailMasked(state) {
		return state.emailMasked;
	},
	getCpf(state) {
		return state.cpf;
	},
	getMetodos(state) {
		return state.metodos;
	},
	getReseId(state) {
		return state.rese_id;
	},
	getExpTime(state) {
		return state.exp_time;
	},
	hasMultipleMethods(state) {
		if (state.metodos != undefined) {
			return state.metodos.length > 1;
		} else {
			return false;
		}
	}
};

export default getters;