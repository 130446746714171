const mutations = {
	setEmailMasked(state, emailMasked) {
		state.emailMasked = emailMasked;
	},
	setCpf(state, cpf) {
		state.cpf = cpf;
	},
	setMetodos(state, metodos) {
		state.metodos = metodos;
	},
	setReseId(state, rese_id) {
		state.rese_id = rese_id;
	},
	setExpTime(state, exp_time) {
		state.exp_time = exp_time;
	},
};

export default mutations;
