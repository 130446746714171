const getters = {
	isLoading(state) {
		return state.loading;
	},

	isLoaded(state) {
		return state.loaded;
	},

	temNomeSocial(state) {
		return typeof state.nomeSocial === 'string'
			&& state.nomeSocial.length > 1;
	},

	nome(state, getters) {
		const nome = getters['temNomeSocial']
			? state.nomeSocial
			: state.nome;

		const partes = nome.split(' ');

		partes.splice(1, partes.length - 2);

		return partes.join(' ');
	},

	foto(state) {
		if (state.foto != '' && state.foto != null) {
			if (state.foto.match(/^https?:\/\//)) {
				return state.foto;
			} else {
				return window.location.protocol + '//' + window.location.hostname + state.foto;
			}
		}

		return 'person_no_picture.svg';
	},

	getEmail: (state) => {
		return state.email;
	},

	getCelular: (state) => {
		return state.celular;
	}
};

export default getters;
