export const VinculoStatusEnum = Object.freeze({
	MATRICULADO: 'matriculado',
	APROVADO: 'aprovado',
	REPROVADO: 'reprovado',
	CANCELADO: 'cancelado',
	DESISTENTE: 'desistente',
});

export default {
	VinculoStatusEnum,
}
