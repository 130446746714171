import createPersistedState from 'vuex-persistedstate';
import { get as shvlGet, set as shvlSet } from 'shvl';

const version = process.env.VUE_APP_VERSION;

const options = {
	key: 'pda-' + version,
	paths: [
		'sessao.token',
		'sessao.refreshToken',
		'sessao.tokenRequestTime',
		'vinculosAcademicos.vinculoSelecionado'
	],
	reducer(state, paths) {
		if (state.sessao.token === null) {
			return {};
		}

		return paths.reduce(function (substate, path) {
			return shvlSet(substate, path, shvlGet(state, path));
		}, {})
	}
};

export default createPersistedState(options);
