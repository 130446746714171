const action = {
	downloadCertificate: async ({commit, getters}) => {
		commit('loading', true);

		try {
			const certificateURL = getters.getLinkDownload;

			window.open(certificateURL, '_blank');
		} catch (error) {
			return false;
		} finally {
			commit('loading', false);
		}
	},

	downloadComprovanteAssinatura: async ({ commit, getters }) => {
		commit('loading', true);

		try {
			const comprovanteURL = getters.getLinkComprovanteAssinatura;

			window.open(comprovanteURL, '_blank');
		} catch (error) {
			return false;
		} finally {
			commit('loading', false);
		}
	}
}

export default action;
