import { isNil } from 'lodash'
import { compareDateDesc } from '@commons/date';

const getters = {
	isLoading: (state) => {
		return state.loading;
	},

	getProgress: (state) => {
		return state.progress;
	},

	isModalActive: (state) => {
		return state.isModalActive;
	},

	getActiveStep: (state) => {
		return state.activeStep;
	},

	contemCobranca: (state) => {
		if (
			!isNil(state.tipoSelecionado) &&
			!isNil(state.tipoSelecionado.reqt_valor_default) &&
			state.tipoSelecionado.reqt_valor_default > 0
		) {
			return true
		}

		return null;
	},

	getValorCobranca: (state) => {
		if (!isNil(state.tipoSelecionado) && !isNil(state.tipoSelecionado.reqt_valor_default)) {
			return state.tipoSelecionado.reqt_valor_default;
		}

		return 0.00;
	},

	getSecoes: (state) => {
		return state.secoes;
	},

	getSecaoSelecionada: (state) => {
		return state.secaoSelecionada;
	},

	getCategoriaSelecionada: (state) => {
		return state.categoriaSelecionada;
	},

	getTipoSelecionado: (state) => {
		return state.tipoSelecionado;
	},

	getOrientacaoRequerimento: (state) => {
		if (!isNil(state.tipoSelecionado) && !isNil(state.tipoSelecionado.reqt_descricao_web)) {
			return state.tipoSelecionado.reqt_descricao_web
		}

		return ""
	},

	getMotivoSolicitacao: (state) => {
		return state.motivoSolicitacao;
	},

	getFiles: (state) => {
		return state.files;
	},

	listagem(state) {
		return state.listagem;
	},

	listagemOrdenada(state) {
		const statusDetalheOrdem = {
			'PAGAR': 1,
			'ACOMPANHAR': 2,
			'CONSULTAR': 3,
		};

		return state.listagem.sort((a, b) => {
			if (a.status_detalhe != b.status_detalhe) {
				return statusDetalheOrdem[a.status_detalhe] - statusDetalheOrdem[b.status_detalhe];
			}

			return compareDateDesc(a.requ_data_solicitacao, b.requ_data_solicitacao);
		});
	},

	temAberto(state) {
		return Boolean(
			state.listagem
				.find(requerimento => requerimento.status_detalhe !== 'CONSULTAR')
		);
	},

	detalhes(state) {
		return id => {
			if (typeof state.detalhes[id] === 'undefined') {
				return false;
			}

			return state.detalhes[id];
		}
	},

	temFiltro(state) {
		if (state.ultimoFiltro.length === 0) {
			return false;
		}

		const params = JSON.parse(state.ultimoFiltro);

		return Boolean(params.filtro);
	},

	getRetornoPagamento(state) {
		return state.retornoPagamento;
	},

	getOpenFeedbackModal(state) {
		return state.feedback.openModal;
	},

	getDisciplinasNaoDisponiveis(state) {
		return state.disciplinasNaoDisponiveis;
	},

	getDisciplinasNaoDisponiveisSelecionadas(state) {
		return state.disciplinasNaoDisponiveisSelecionadas;
	},

	getOpenDetalheFeedbackModal(state) {
		return state.feedback.openDetalheModal;
	},
};

export default getters;
