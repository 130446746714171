const mutations = {
	setAbertos: (state, abertos) => {
		state.abertos = abertos;
	},

	setAtrasados: (state, atrasados) => {
		state.atrasados = atrasados;
	},

	setPagos: (state, pagos) => {
		state.pagos = pagos;
	},

	loading: (state, loading) => {
		state.isLoading = loading;
	}
}

export default mutations;
