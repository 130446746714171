export function validateToken(token) {
	if (!token) {
		return false;
	}

	token = String(token);

	return token.length > 20
		&& token.split('.').length === 3;
}
