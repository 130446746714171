const getters = {
	getNaoEnviados: (state) => {
		return state.naoEnviados;
	},

	getInvalidados: (state) => {
		return state.invalidados;
	},

	getEmAnalise: (state) => {
		return state.emAnalise;
	},

	getValidados: (state) => {
		return state.validados;
	},

	isLoading: (state) => {
		return state.isLoading;
	},

	contemDados: (state) => {
		if (state.invalidados.length || state.naoEnviados.length || state.emAnalise.length || state.validados.length) {
			return true;
		}

		return false;
	},

	getEstruturaModalEnvioDocumentos: (state) => {
		return state.estruturaModalEnvioDocumentos;
	},

	getProgress: (state) => {
		return state.progress;
	},

	getActiveStep: (state) => {
		return state.activeStep;
	}
};

export default getters;
