export const InteresseValorEnum = Object.freeze({
	MEDIO: 1,
	ALTO: 2,
	MUITO_ALTO: 3,
});

export const InteresseIconeEnum = Object.freeze({
	[InteresseValorEnum.MEDIO]: 'experiencias/interesse_2',
	[InteresseValorEnum.ALTO]: 'experiencias/interesse_3',
	[InteresseValorEnum.MUITO_ALTO]: 'experiencias/interesse_4',
});

export const InteresseIconeSelecionadoEnum = Object.freeze({
	[InteresseValorEnum.MEDIO]: 'experiencias/interesse_2_selecionado',
	[InteresseValorEnum.ALTO]: 'experiencias/interesse_3_selecionado',
	[InteresseValorEnum.MUITO_ALTO]: 'experiencias/interesse_4_selecionado',
});

export const InteresseNomeEnum = Object.freeze({
	[InteresseValorEnum.MEDIO]: 'Médio',
	[InteresseValorEnum.ALTO]: 'Alto',
	[InteresseValorEnum.MUITO_ALTO]: 'Muito alto',
});

export const InteresseStatusEnum = Object.freeze({
	AGUARDANDO: 'aguardando aprovação',
	REPROVADO: 'reprovado',
});

export default {
	InteresseIconeEnum,
	InteresseIconeSelecionadoEnum,
	InteresseNomeEnum,
	InteresseStatusEnum,
	InteresseValorEnum,
};
