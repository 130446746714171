const actions = {
	loading({ commit }, isLoading) {
		commit('loading', Boolean(isLoading));
	},

	setMobile({ commit }, isMobile) {
		commit('setMobile', isMobile);
	},
};

export default actions
