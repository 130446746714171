const state = {
	invalidados: [],
	naoEnviados: [],
	emAnalise: [],
	validados: [],
	isLoading: false,
	estruturaModalEnvioDocumentos: null,
	progress: 25,
	activeStep: 1,
};

export default state;
