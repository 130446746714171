const state = {
	acessoUlife: {},
	acessoExperiencias: {},
	acessoCertificadoPosDigital: {},
	acessoAtividadesComplementares: {},
	acessoPagamentos: {},
	acessoDocumentos: {},
	acessos: [],
	urlSso: ''
};

export default state;
