const state = {
	isLoading: false,
	termosFiltroAceito: false,
	vinculoSelecionado: '',
	permiteFiltrarTodos: true,
	permiteFiltrarTodosAtivos: true,
	vinculosFiltro: {}
};

export default state;
