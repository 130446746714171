import http from '@commons/http';

const actions = {

	todosServicos: async ({ commit, rootGetters }) => {
		commit('ui/loading', true, { root: true });

		const vinculoAcademicoSelecionado = typeof rootGetters['vinculosAcademicos/getVinculoSelecionado'] != 'string' ? rootGetters['vinculosAcademicos/getVinculoSelecionado'] : null;
		let viacQuery = '';
		if (vinculoAcademicoSelecionado != null) {
			viacQuery = `?viacId=${vinculoAcademicoSelecionado.viac_id}`
		}

		try {
			const data = (await http.get(`/v1/aluno/links-servicos${viacQuery}`)).data;

			const acessosDefault = [];

			for (const acesso of data.message) {
				switch (acesso.icon) {
					case 'ulife':
						commit('setAcessoUlife', acesso);
						break;

					case 'experiencias':
						commit('setAcessoExperiencias', acesso);
						break;

					case 'envio_documentos':
						commit('setAcessoDocumentos', acesso);
						break;

					case 'segunda_via_doc':
						commit('setAcessoPagamentos', acesso);
						break;

					case 'certificado_digital':
						commit('setAcessoCertificadoPosDigital', acesso);
						break;

					case 'atividades_complementares':
						commit('setAcessoAtividadesComplementares', acesso);
						break;


					default:
						acessosDefault.push(acesso);
				}
			}

			commit('setAcessos', acessosDefault);
		} finally {
			commit('ui/loading', false, { root: true });
		}
	},

	callSsoUrl: async ({ commit }) => {
		const data = (await http.get('/v1/aluno/sso-ulife')).data;

		if (data.status == 'success') {
			commit('setUrlSso', data.url);
		}
	},

	clearSsoUrl({ commit }) {
		commit('setUrlSso', '');
	},
};

export default actions;
