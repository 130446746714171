const mutations = {
	setAcessoUlife: (state, acesso) => {
		state.acessoUlife = acesso;
	},

	setAcessoExperiencias: (state, acesso) => {
		state.acessoExperiencias = acesso;
	},

	setAcessoDocumentos: (state, acesso) => {
		state.acessoDocumentos = acesso;
	},

	setAcessoPagamentos: (state, acesso) => {
		state.acessoPagamentos = acesso;
	},

	setAcessoCertificadoPosDigital: (state, acesso) => {
		state.acessoCertificadoPosDigital = acesso;
	},

	setAcessoAtividadesComplementares: (state, acesso) => {
		state.acessoAtividadesComplementares = acesso;
	},

	setAcessos: (state, acessos) => {
		state.acessos = acessos;
	},

	setUrlSso: (state, urlSso) => {
		state.urlSso = urlSso;
	},
};

export default mutations;
