const state = function () {
	return {
		emailMasked: '',
		cpf: '',
		metodos: [],
		rese_id: null,
		exp_time: null

	};
};


export default state;
