const getters = {
	getVinculosFiltro: (state) => {
		return state.vinculosFiltro;
	},

	permiteFiltrarTodos: (state) => {
		return state.permiteFiltrarTodos;
	},

	permiteFiltrarTodosAtivos: (state) => {
		return state.permiteFiltrarTodosAtivos && state.permiteFiltrarTodos;
	},

	getVinculoSelecionado: (state) => {
		return state.vinculoSelecionado;
	},

	isVinculoTodos: state => {
		if (typeof state.vinculoSelecionado !== 'string') {
			return false;
		}

		const todos = [
			'todos os cursos ativos',
			'todos os cursos'
		];

		return todos.includes(state.vinculoSelecionado.toLowerCase());
	},

	isLoading: (state) => {
		return state.isLoading;
	},

	getAceiteTermosFiltro: (state) => {
		return state.termosFiltroAceito;
	}
};

export default getters;
