const getters = {
	residencial(state) {
		return state.residencial;
	},

	cobranca(state) {
		return state.cobranca;
	},

	isMesmoEnderecoResidencialCobranca(state) {
		return state.residencial.cep == state.cobranca.cep
			&& state.residencial.endereco == state.cobranca.endereco
			&& state.residencial.numero == state.cobranca.numero
			&& state.residencial.complemento == state.cobranca.complemento;
	}
};

export default getters;
