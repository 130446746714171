import { isPlainObject, isNil } from 'lodash';

const getters = {
	getAcessos: (state, _, __, rootGetters ) => {
		const acessos = state.acessos.map(acesso => {
			if (acesso.label.toLowerCase() == "plano de ensino") {
				const vinculoSelecionado = rootGetters["vinculosAcademicos/getVinculoSelecionado"];
				if ( isPlainObject(vinculoSelecionado) ) {
					let url = `${acesso.href}?nens_id=${vinculoSelecionado.nens_id}&curs_id=${vinculoSelecionado.curs_id}`;
					acesso.href = url;
				}
			}
			return acesso;
		});
		return acessos;
	},

	getAcessoPagamentos: (state) => {
		return state.acessoPagamentos;
	},

	getAcessoExperiencias: (state) => {
		return state.acessoExperiencias;
	},

	getAcessoDocumentos: (state) => {
		return state.acessoDocumentos;
	},

	getAcessoCertificadoPosDigital: (state) => {
		return state.acessoCertificadoPosDigital;
	},

	getAcessoAtividadesComplementares: (state) => {
		return state.acessoAtividadesComplementares;
	},

	getAcessosByName: (state) => (filter = []) => {
		return state.acessos.filter(acesso => filter.includes(acesso.label.toLowerCase()));
	},

	getAcessoUlife: (state) => {
		return state.acessoUlife;
	},

	contemServicos: (state) => {
		return state.acessos.length > 0 ? true : false;
	},

	getUrlSso: (state) => {
		return state.urlSso;
	},

	permiteAcessarAtividadesComplementares: (_, __, ___, rootGetters) => {
		const vinculoSelecionado = rootGetters['vinculosAcademicos/getVinculoSelecionado'];

		if ((isPlainObject(vinculoSelecionado) && parseInt(vinculoSelecionado.nens_id) == 2) || !isPlainObject(vinculoSelecionado)){
			return false;
		}

		return true;

	},

	permiteVisualizarExperiencias: (_, __, ___, rootGetters) => {
		const vinculoSelecionado = rootGetters['vinculosAcademicos/getVinculoSelecionado'];
		const validaExperiencia = vinculo => {
			return parseInt(vinculo.nens_id, 10) == 2 &&
					(parseInt(vinculo.moex_id, 10) == 2);
		};

		// TODO REMOVER O MOEX_ID 5 e 1  POIS É PERMITIDO APENAS 2XE2A (id=2)
		if (isPlainObject(vinculoSelecionado) && validaExperiencia(vinculoSelecionado)){
			return true;
		} else if (!isPlainObject(vinculoSelecionado)) {
			const vinculos = rootGetters['vinculosAcademicos/getVinculosFiltro'];

			if(!isNil(vinculos.Ativo)) {
				return vinculos.Ativo.some(validaExperiencia);
			}
		}

		return false;
	},

	permiteAcessarExperiencias: (_, __, ___, rootGetters) => {
		const vinculoSelecionado = rootGetters['vinculosAcademicos/getVinculoSelecionado'];

		if ((isPlainObject(vinculoSelecionado)
				&& parseInt(vinculoSelecionado.nens_id) == 1
				&& (parseInt(vinculoSelecionado.moex_id, 10) != 2))
			|| !isPlainObject(vinculoSelecionado)){
			return false;
		}

		return true;

	},

	permiteVisualizarAtividadesComplementares: (state, getters, _, rootGetters) => {
		const vinculoSelecionado = rootGetters['vinculosAcademicos/getVinculoSelecionado'];
		const verificaAcessoCursoSelecionado = rootGetters['cursos/verificaAcessoCursoSelecionado'];

		if (getters.permiteAcessarAtividadesComplementares ||
			(
				!isPlainObject(vinculoSelecionado) &&
				['todos os cursos ativos', 'todos os cursos'].includes(vinculoSelecionado.toLowerCase()) &&
				verificaAcessoCursoSelecionado
			)
		) {
			return true;
		}

		return false;
	},

	permiteAcessarCertificadoPosDigital: (_, __, ___, rootGetters) => {
		const vinculoSelecionado = rootGetters['vinculosAcademicos/getVinculoSelecionado'];
		const existePosFormado = rootGetters['cursos/getExistePosFormado'];
		const verificaAcessoCursoSelecionado = rootGetters['cursos/verificaAcessoCursoSelecionado'];
		if (
			isPlainObject(vinculoSelecionado) &&
			parseInt(vinculoSelecionado.nens_id) == 2 &&
			vinculoSelecionado.status_descricao.toLowerCase() == 'formado' &&
			existePosFormado &&
			verificaAcessoCursoSelecionado
		) {
			return true;
		}

		return false;
	},

	permiteVisualizarAcessoCertificadoPosDigital: (state, getters, _, rootGetters) => {
		const vinculoSelecionado = rootGetters['vinculosAcademicos/getVinculoSelecionado'];
		const existePosFormado = rootGetters['cursos/getExistePosFormado'];
		const verificaAcessoCursoSelecionado = rootGetters['cursos/verificaAcessoCursoSelecionado'];

		if (
			getters.permiteAcessarCertificadoPosDigital ||
			(
				!isPlainObject(vinculoSelecionado) &&
				['todos os cursos ativos', 'todos os cursos'].includes(vinculoSelecionado.toLowerCase()) &&
				existePosFormado &&
				verificaAcessoCursoSelecionado
			)
		) {
			return true;
		}

		return false;
	}
};

export default getters;
