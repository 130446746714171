import { isPlainObject } from 'lodash';

const getters = {
	openModal: (state) => {
		return state.openModal;
	},

	getStatus: (_, __, ___, rootGetters) => {
		const vinculoSelecionado = rootGetters['vinculosAcademicos/getVinculoSelecionado'];
		if (isPlainObject(vinculoSelecionado)) {
			return vinculoSelecionado.status_certificado;
		}

		return false;
	},

	getLinkDownload: (_, __, ___, rootGetters) => {
		const vinculoSelecionado = rootGetters['vinculosAcademicos/getVinculoSelecionado'];
		if (isPlainObject(vinculoSelecionado)) {
			return vinculoSelecionado.link_download_certificado;
		}

		return false;
	},

	getLinkComprovanteAssinatura: (_, __, ___, rootGetters) => {
		const vinculoSelecionado = rootGetters['vinculosAcademicos/getVinculoSelecionado'];
		if (isPlainObject(vinculoSelecionado)) {
			return vinculoSelecionado.link_comprovante_assinatura_certificado;
		}

		return false;
	},

	isLoading: (state) => {
		return state.loading;
	}
}

export default getters;
