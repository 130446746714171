const mutation = {
	openModal: (state, openModal) => {
		state.openModal = openModal;
	},

	loading: (state, loading) => {
		state.loading = loading;
	}
}

export default mutation;
