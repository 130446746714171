const mutations = {
	residencial(state, payload) {
		state.residencial.cep = payload.cep;
		state.residencial.endereco = payload.endereco;
		state.residencial.numero = payload.numero;
		state.residencial.complemento = payload.complemento;
		state.residencial.bairro = payload.bairro;
		state.residencial.cidade = payload.cidade;
		state.residencial.estado = payload.estado;
		state.residencial.pais = payload.pais;
	},

	cobranca(state, payload) {
		state.cobranca.cep = payload.cep;
		state.cobranca.endereco = payload.endereco;
		state.cobranca.numero = payload.numero;
		state.cobranca.complemento = payload.complemento;
		state.cobranca.bairro = payload.bairro;
		state.cobranca.cidade = payload.cidade;
		state.cobranca.estado = payload.estado;
		state.cobranca.pais = payload.pais;
	},
};

export default mutations;
