import http from '@commons/http';

const actions = {
	async carregarDados({ commit, getters }, forcar = false) {
		if (getters.isLoading || (!forcar && getters.isLoaded)) {
			return;
		}

		commit('loading', true);

		try {
			const { data } = await http.get('/v1/aluno/dados-pessoais');

			const personalData = {
				email: data.message.pess_email,
				celular: data.message.pess_fone_celular,
				paisPossuemFormacao: data.message.pess_primera_geracao_graduacao == 't' ? 'sim' : 'nao',
				empresa: data.message.pess_empresa,
				cargo: data.message.pess_cargo,
				urlLinkedin: data.message.pess_linkedin,
				urlFacebook: data.message.pess_facebook,
				corRaca: data.message.cora_id,
				foto: data.message.pess_caminho_foto,
			};

			commit('dados', personalData);

			const homeAddress = {
				cep: data.pess_cep,
				endereco: data.pess_end,
				numero: data.pess_end_numero,
				complemento: data.pess_end_compl,
				bairro: data.pess_end_bairro,
				cidade: data.cida_id_endereco,
				estado: '',
				pais: '',
			};

			commit('aluno/enderecos/residencial', homeAddress, { root: true });

			const billingAddress = {
				cep: data.pess_cep_cobranca,
				endereco: data.pess_end_cobranca,
				numero: data.pess_end_numero_cobranca,
				complemento: data.pess_end_compl_cobranca,
				bairro: data.pess_end_bairro_cobranca,
				cidade: data.cida_id_cobranca,
				estado: '',
				pais: '',
			};

			commit('aluno/enderecos/cobranca', billingAddress, { root: true });

			commit('loaded', true);
		} finally {
			commit('loading', false);
		}
	},

	atualizaDados: async ({ dispatch }, dados = {}) => {
		dispatch('ui/loading', true, { root: true });

		try {
			await http.put("/v1/aluno/dados-pessoais", new URLSearchParams(Object.entries(dados)).toString());
		} catch (error) {
			console.error(error);
		} finally {
			dispatch('ui/loading', false, { root: true });
		}
	}
};

export default actions;
