const mutations = {
	setInvalidados: (state, invalidados) => {
		state.invalidados = invalidados;
	},

	setNaoEnviados: (state, naoEnviados) => {
		state.naoEnviados = naoEnviados;
	},

	setEmAnalise: (state, emAnalise) => {
		state.emAnalise = emAnalise;
	},

	setValidados: (state, validados) => {
		state.validados = validados;
	},

	loading: (state, loading) => {
		state.isLoading = loading;
	},

	setEstruturaModalEnvioDocumentos: (state, estruturaModal) => {
		state.estruturaModalEnvioDocumentos = estruturaModal;
	},

	setProgress: (state, progress) => {
		state.progress = progress;
	},

	setActiveStep: (state, activeStep) => {
		state.activeStep = activeStep;
	}
};

export default mutations;
