const mutations = {
	setLoading: (state, isLoading) => {
		state.loading = isLoading;
	},

	setModalActive: (state, isActive) => {
		state.isModalActive = isActive;
		state.activeStep = 'aviso';
	},

	setActiveStep: (state, activeStep) => {
		state.activeStep = activeStep;
	},

	setProgress: (state, progress) => {
		state.progress = progress;
	},

	setSecoes: (state, secoes) => {
		state.secoes = secoes;
	},

	setSecaoSelecionada: (state, secaoSelecionada) => {
		state.secaoSelecionada = secaoSelecionada;
	},

	setCategoriaSelecionada: (state, categoriaSelecionada) => {
		state.categoriaSelecionada = categoriaSelecionada;
	},

	setTipoSelecionado: (state, tipoSelecionado) => {
		state.tipoSelecionado = tipoSelecionado;
	},

	setTipoDados: (state, tipoDados) => {
		state.tipoSelecionado = {...state.tipoSelecionado, ...tipoDados};
	},

	setMotivoSolicitacao: (state, motivoSolicitacao) => {
		state.motivoSolicitacao = motivoSolicitacao;
	},

	setFiles: (state, files) => {
		state.files = files;
	},

	setUltimaCarga(state, ultimaCarga) {
		state.ultimaCarga = ultimaCarga;
	},

	setUltimoFiltro(state, ultimoFiltro) {
		state.ultimoFiltro = ultimoFiltro;
	},

	setListagem(state, listagem) {
		state.listagem = listagem;
	},

	setDetalhada(state, datalhada) {
		const id = datalhada.requ_id;

		state.detalhes[id] = datalhada;
	},

	setLabelExtra(state, labelExtra) {
		state.labelExtra = labelExtra;
	},

	setInformacaoExtra(state, informacaoExtra) {
		state.informacaoExtra = informacaoExtra;
	},

	setRetornoPagamento(state, retornoPagamento) {
		state.retornoPagamento = retornoPagamento;
	},

	setOpenModalFeedback(state, openModal) {
		state.feedback.openModal = openModal;
	},

	setDisciplinasNaoDisponiveis(state, disciplinasNaoDisponiveis) {
		state.disciplinasNaoDisponiveis = disciplinasNaoDisponiveis;
	},

	setDisciplinasNaoDisponiveisSelecionadas(state, disciplinasNaoDisponiveisSelecionadas) {
		state.disciplinasNaoDisponiveisSelecionadas = disciplinasNaoDisponiveisSelecionadas;
	},

	setOpenModalDetalhe(state, openDetalheModal) {
		state.feedback.openDetalheModal = openDetalheModal;
		state.activeStep = 'detalhe';
		state.progress = 50;
	},
};

export default mutations;
