import { isNil } from 'lodash';
import moment from 'moment';

const getters = {
	getCursos: state => {
		return state.cursos;
	},

	getCursosAtivos: state => {
		return state.cursos
			.filter(curso => {
				return curso.status_descricao.toLowerCase() === 'ativo';
			})
			.map(curso => {
				return curso.curs_id;
			});
	},

	getExistePosFormado: state => {
		const hasPos = state.cursos.filter(curso => {
			return (
				parseInt(curso.nens_id) == 2 &&
				curso.status_descricao.toLowerCase() === 'formado' &&
				((!isNil(curso.status_certificado) &&
					curso.status_certificado.length > 0 &&
					parseInt(curso.status_certificado) > 1) ||
					moment(curso.data_ocorrencia_formado) >= moment('2022-09-30'))
			);
		});

		return hasPos.length > 0 ? true : false;
	},

	verificaAcessoCursoSelecionado: (_, __, ___, rootGetters) => {
		const vinculoSelecionado =
			rootGetters['vinculosAcademicos/getVinculoSelecionado'];

		if (
			(!isNil(vinculoSelecionado.status_certificado) &&
				vinculoSelecionado.status_certificado.length > 0 &&
				parseInt(vinculoSelecionado.status_certificado) > 1) ||
			moment(vinculoSelecionado.data_ocorrencia_formado) >= moment('2022-09-30')
		) {
			return true;
		}

		return false;
	}
};

export default getters;
