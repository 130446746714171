const state = {
	loading: false,
	loaded: false,
	nome: '',
	nomeSocial: '',
	email: '',
	celular: '',
	paisPossuemFormacao: '',
	empresa: '',
	cargo: '',
	urlLinkedin: '',
	urlFacebook: '',
	corRaca: '',
	foto: '',
};

export default state;
