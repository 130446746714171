import { isPrd } from '@commons/enviroment';

const clientId = '6b66395c-e5a6-4f78-93cf-52ecc69d0f35';
const authority = 'https://login.microsoftonline.com/f310b526-e195-4805-a55e-67e28f2fefdb';
const redirectUri = '/login';
const emailLoginDomain = '@ulife.com.br';

const clientId_QEA = '66838cb0-47d1-4db5-9b85-3f8f6b5d6ebb';
const authority_QEA = 'https://login.microsoftonline.com/80f6a699-b024-4b7b-8cca-5ecfdd2a2fe3';
const redirectUri_QEA = '/login';
const emailLoginDomain_QEA = '@homolog.animaeducacao.com.br';

const getClientId = () => {
	if(isPrd) {
		return clientId;
	}

	return clientId_QEA;
}
const getAuthority = () => {
	if(isPrd) {
		return authority;
	}

	return authority_QEA;
}
const getRedirectUri = () => {
	if(isPrd) {
		return redirectUri;
	}

	return redirectUri_QEA;
}
const getEmailLoginDomain = () => {
	if(isPrd) {
		return emailLoginDomain;
	}

	return emailLoginDomain_QEA;
}

export {
	getClientId,
	getAuthority,
	getRedirectUri,
	getEmailLoginDomain
}
