const state = {
	residencial: {
		cep: '',
		endereco: '',
		numero: '',
		complemento: '',
		bairro: '',
		cidade: '',
		estado: '',
		pais: '',
	},
	cobranca: {
		cep: '',
		endereco: '',
		numero: '',
		complemento: '',
		bairro: '',
		cidade: '',
		estado: '',
		pais: '',
	},
};

export default state;
