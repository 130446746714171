import { validateToken } from './commons';

const getters = {
	isLogged(state) {
		return state.token && state.token.length > 1;
	},

	isValidToken(state) {
		return validateToken(state.token);
	},

	isVerified(state) {
		return state.verified;
	},

	hasBloqueioDisciplinas(_, getters) {
		return getters['getBloqueioDisciplinas'] != null;
	},

	getBloqueioDisciplinas(state, _, __, rootGetters) {
		const vinculo = rootGetters['vinculosAcademicos/getVinculoSelecionado'];

		if (
			!state.bloqueioDisciplinas
			|| !vinculo
			|| typeof vinculo.nens_id == 'undefined'
		) {
			return null;
		}

		if (rootGetters['vinculosAcademicos/isVinculoTodos']) {
			return state.bloqueioDisciplinas[0].mensagem;
		}

		for (const bloqueio of state.bloqueioDisciplinas) {
			if (
				vinculo.nens_id == bloqueio.nens_id
				&& vinculo.curs_id == bloqueio.curs_id
				&& vinculo.habi_id == bloqueio.habi_id
				&& vinculo.turn_id == bloqueio.turn_id
				&& vinculo.viac_sequencia == bloqueio.viac_sequencia
				&& vinculo.periodo == bloqueio.peri_id
			) {
				return bloqueio.mensagem;
			}
		}

		return null;
	},
};

export default getters;
