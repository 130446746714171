import { includes, capitalize } from 'lodash';

const changeAPIEnvironment = (location) => {
	const search = 'estudantes';
	const replace = 'api';
	const ports_dev = ['5000', '5001', '5002', '5003', '5004', '5005'];

	let port = location.port;
	if (ports_dev.includes(port)) return false;

	return `${location.protocol}//${location.hostname.replace(search, replace)}:${port}`;
};

const getDynamicIeBySubDomain = (hostname, iesList) => {
	for (const [key, value] of Object.entries(iesList)) {
		if (includes(hostname, value))
			return key;
	}

	for (var key in iesList) {
		if (includes(hostname, key))
			return key;
	}

	return 'uam';
};

const checkCreditCardType = (cardNumber) => {
	const cleanCardNumber = cardNumber.replace(/ /g,'');
	const cardsRegex = {
		'american_express': /^(?:3[47][0-9]{13})$/,
		'diners_club': /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
		'mastercard': /^((5(([1-2]|[4-5])[0-9]{8}|0((1|6)([0-9]{7}))|3(0(4((0|[2-9])[0-9]{5})|([0-3]|[5-9])[0-9]{6})|[1-9][0-9]{7})))|((508116)\\d{4,10})|((502121)\\d{4,10})|((589916)\\d{4,10})|(2[0-9]{15})|(67[0-9]{14})|(506387)\\d{4,10})/,
		'visa': /^4[0-9]{12}(?:[0-9]{3})?$/,
		'elo': /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/,
		'hipercard': /^606282|^3841(?:[0|4|6]{1})0/,
	};

	let cardFlag = false;

	Object.entries(cardsRegex).forEach(([key, value]) => {
		if (new RegExp(value).test(cleanCardNumber)) {
			cardFlag = key;
		}
	});

	return cardFlag;
};

const downloadFile = (data, fileName, mimeType) => {
	const blob = new Blob([data], { type: mimeType });

	const link = document.createElement('a');

	link.href = URL.createObjectURL(blob);
	link.download = fileName;

	document.body.append(link);

	link.click();
	link.remove();

	setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};

const pdfDownloader = (data, fileName) => {
	downloadFile(data, fileName, 'application/pdf');
}

const capitalizeAll = (value) => {
	return value
		.split(' ')
		.map(text => {
			return text.length > 2 ? capitalize(text) : text.toLowerCase();
		})
		.join(' ');
}

export {
	getDynamicIeBySubDomain,
	changeAPIEnvironment,
	checkCreditCardType,
	downloadFile,
	pdfDownloader,
	capitalizeAll,
}
