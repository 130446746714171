import moment from 'moment';

const getters = {
	getPagos: (state) => {
		let pagos = state.pagos;

		return pagos.sort((a, b) => {
			return moment(a.data_vencimento) - moment(b.data_vencimento);
		});
	},

	getAbertos: (state) => {
		let abertos = state.abertos;

		return abertos.sort((a, b) => {
			return moment(a.data_vencimento) - moment(b.data_vencimento);
		});
	},

	getAtrasados: (state) => {
		let atrasados = state.atrasados;

		return atrasados.sort((a, b) => {
			return moment(a.data_vencimento) - moment(b.data_vencimento);
		});
	},

	isLoading: (state) => {
		return state.isLoading;
	},

	contemDados: (state) => {
		if (state.atrasados.length || state.abertos.length || state.pagos.length) {
			return true;
		}

		return false;
	},

	getById(state) {
		return id => {
			const lists = [
				state.atrasados,
				state.abertos,
				state.pagos,
			];

			for (const list of lists) {
				for (const doc of list) {
					if (doc.doc_id == id) {
						return doc;
					}
				}
			}

			return null;
		};
	},
};

export default getters;
