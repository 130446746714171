const mutations = {
	dados(state, payload) {
		state.email = payload.email;
		state.celular = payload.celular;
		state.paisPossuemFormacao = payload.paisPossuemFormacao;
		state.empresa = payload.empresa;
		state.cargo = payload.cargo;
		state.urlLinkedin = payload.urlLinkedin;
		state.urlFacebook = payload.urlFacebook;
		state.corRaca = payload.corRaca;
		state.foto = payload.foto;
	},

	loading(state, payload) {
		state.loading = payload;
	},

	loaded(state, payload) {
		state.loaded = payload;
	},

	setEmail(state, email) {
		state.email = email;
	},

	setCelular(state, celular) {
		state.celular = celular;
	},

	setNome(state, nome) {
		state.nome = nome;
	},

	setNomeSocial(state, nomeSocial) {
		state.nomeSocial = nomeSocial;
	},
};

export default mutations;
