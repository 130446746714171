import pagamentos from './pagamentos';
import documentos from './documentos';
import aluno from './aluno';
import notificacoes from './notificacoes';
import ambiente from './ambiente';
import sessao from './sessao';
import vinculosAcademicos from './vinculos-academicos';
import cursos from './cursos';
import ui from './ui';
import servicos from './servicos';
import solicitacoes from './solicitacoes';
import senha from './senha';
import experiencias from './experiencias';

const modules = {
	pagamentos,
	documentos,
	aluno,
	notificacoes,
	ambiente,
	sessao,
	vinculosAcademicos,
	cursos,
	ui,
	servicos,
	solicitacoes,
	senha,
	experiencias,
}

export default modules;
