import actions from './actions';
import state from './state';
import getters from './getters';
import mutations from './mutations';

const toast = {
	namespaced: true,
	actions,
	state,
	getters,
	mutations,
};

export default toast;
